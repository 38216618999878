/**
 * COMPONENT
 * Name: Button
 ********************************* */

.c-button {
  position: relative;
  display: inline-block;
  @include rem(padding, 15px 45px);
  @include font-size(11px, 24px);
  font-weight: $font-bold;
  text-align: center;
  text-transform: uppercase;
  @include rem(letter-spacing, 1px);
  color: $color-black;
  background-color: $color-white;
  @include rem(box-shadow, 0 10px 20px rgba($color-black, 0.2));
  @include rem(border-radius, 30px);
  @include transition([box-shadow, transform])

  &:hover {
    @include rem(box-shadow, 0 15px 25px rgba($color-black, 0.15));
    transform: translateY(-2px);
  }
}
