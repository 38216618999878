/**
 * LAYOUT
 * Name: Gallery
 ********************************* */

.l-gallery {
  position: relative;
  overflow: hidden;
}


  /**
   * CONTAINER
   *********************** */
  .l-gallery__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    html.touchevents & {
      transform: none !important;
      overflow-x: auto;
    }
  }


    /**
     * RACK
     *********************** */
    .l-gallery__rack {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      @include mq($until: medium) {

        &:first-child {
          @include rem(padding-left, $gutter-size);
        }

        &:last-child {
          @include rem(padding-right, $gutter-size);
        }
      }

      @include mq($from: medium) {

        &:first-child {
          @include rem(padding-left, $gutter-size*4);
        }

        &:last-child {
          @include rem(padding-right, $gutter-size*4);
        }
      }
    }

    .l-gallery__rack--reverse {
      align-items: flex-end;
    }


      /**
       * UNIT
       *********************** */
      .l-gallery__unit {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }


        /**
         * ITEM
         *********************** */
        .l-gallery__item {
          opacity: 0;
          animation: appear 2s ease forwards;

          @include mq($until: medium) {
            @include rem(padding, $gutter-size/4);
          }

          @include mq($from: medium) {
            @include rem(padding, $gutter-size/2);
          }
        }

          .l-gallery__inner {
            @include transition([opacity]);

            .l-gallery.is-hovered & {
              opacity: 0.6;

              &:hover {
                opacity: 1;
              }
            }
          }
