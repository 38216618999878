/**
 * COMPONENT
 * Name: Title
 ********************************* */

.c-title {
  position: relative;
  font-family: $font-secondary;
  @include font-size(30px, 42px);
  @include rem(letter-spacing, 0.6px);
}
