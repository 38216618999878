/**
 * VIEW
 * Name: Highlight
 ********************************* */

.v-highlight {
  position: relative;
  color: $color-white;
  background-color: $color-secondary;
}


  /**
   * CONTAINER
   *********************** */
  .v-highlight__container {
    @include rem(padding-top, $gutter-size*6);
    @include rem(padding-bottom, $gutter-size*6);
    text-align: center;
  }

    .v-highlight__heading {}

    .v-highlight__action {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size*3);
      }
    }
