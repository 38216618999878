/**
 * LAYOUT
 * Name: Overlay
 ********************************* */

.l-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease, z-index 0.5s ease;

  body.is-overlayed & {
    pointer-events: all;
    opacity: 1;
    z-index: $z-overlay;
    transition: opacity 1.2s ease 0.3s, z-index 1.2s ease 0.3s;
  }
}


  /**
   * CONTAINER
   *********************** */
  .l-overlay__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
  }

    .l-overlay__close {
      position: absolute;
      @include rem(top, $gutter-size);
      @include rem(right, $gutter-size);
      @include rem(padding, $gutter-size);
      color: $color-black;
      transition: color 0.5s ease;
      cursor: pointer;
      z-index: 99;

      &:hover {
        color: $color-secondary;
      }
    }

    .l-overlay__content {
      width: 100%;
    }
