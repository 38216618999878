/**
 * COMPONENT
 * Name: Thumbnail
 ********************************* */

.c-thumbnail {
  position: relative;
  @include rem(border-radius, 20px);
  overflow: hidden;
}


  /**
   * ILLUSTRATION
   *********************** */
  .c-thumbnail__illustration {}

    .c-thumbnail__picture {}

    .c-thumbnail__picture--245 {
      @include mq($until: medium) { width: rem(122.5px); }
      @include mq($from: medium) { width: rem(245px); }
    }

    .c-thumbnail__picture--250 {
      @include mq($until: medium) { width: rem(125px); }
      @include mq($from: medium) { width: rem(250px); }
    }

    .c-thumbnail__picture--380 {
      @include mq($until: medium) { width: rem(190px); }
      @include mq($from: medium) { width: rem(380px); }
    }

    .c-thumbnail__picture--390 {
      @include mq($until: medium) { width: rem(195px); }
      @include mq($from: medium) { width: rem(390px); }
    }

    .c-thumbnail__picture--510 {
      @include mq($until: medium) { width: rem(255px); }
      @include mq($from: medium) { width: rem(510px); }
    }

    .c-thumbnail__picture--520 {
      @include mq($until: medium) { width: rem(260px); }
      @include mq($from: medium) { width: rem(520px); }
    }

    .c-thumbnail__picture--640 {
      @include mq($until: medium) { width: rem(320px); }
      @include mq($from: medium) { width: rem(640px); }
    }


  /**
   * CONTAINER
   *********************** */
  .c-thumbnail__container {

    html.touchevents & {
      display: none;
    }

    html.no-touchevents & {
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color-secondary, 0.8);
      @include rem(border-radius, 20px);
      opacity: 0;
      cursor: pointer;
    }

    html.no-touchevents .c-thumbnail:hover & {
      @include transition([opacity]);
      opacity: 1;
    }
  }

    .c-thumbnail__content {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      @include rem(width, 160px);
      @include rem(height, 160px);
      @include rem(padding, 10px 15px);
      text-align: center;
      color: $color-black;
      background-color: $color-white;
      @include rem(border-radius, 80px);
      @include rem(box-shadow, 0 0 50px $color-white);

      &:before {
        content: url('/assets/images/front/emojis/rocket.png');
        position: absolute;
        @include rem(top, -15px);
        @include rem(right, -15px);
        @include rem(bottom, -15px);
        @include rem(left, -15px);
        @include font-size(40px, 40px);
        text-align: left;
        pointer-events: none;

        .c-thumbnail:hover & {
          animation: rotate 6s linear infinite;
        }
      }
    }

      .c-thumbnail__title {
        font-family: $font-secondary;
        @include font-size(14px, 20px);
        font-weight: $font-bold;
        text-transform: uppercase;
        @include rem(letter-spacing, 3px);
        color: $color-secondary;
      }

      .c-thumbnail__description {
        @include font-size(12px, 18px);
        font-weight: $font-bold;

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/2);
        }
      }
