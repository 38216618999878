/**
 * COMPONENT
 * Name: Viewer
 ********************************* */

.c-viewer {
  position: relative;
}


  /**
   * CONTAINER
   *********************** */
  .c-viewer__container {

    @include mq($until: large) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }


    /**
     * BACKGROUND
     *********************** */
    .c-viewer__background {}

      .c-viewer__items {

        .slick-list {
          overflow: visible !important;
        }

        .slick-arrow {

          @include mq($until: large) {
            display: none !important;
          }

          @include mq($from: large) {
            @include appearance;
            position: fixed;
            width: 25vw;
            top: 0;
            bottom: 0;
            text-indent: -9999px;
            outline: none;
            z-index: 9;

            &.slick-prev {
              left: 0;
              cursor: url('/assets/images/front/arrow-left.png') 0 25, w-resize;
            }

            &.slick-next {
              right: 0;
              cursor: url('/assets/images/front/arrow-right.png') 27 25, e-resize;
            }
          }
        }
      }

        .c-viewer__item {
          @include rem(padding-right, $gutter-size/2);
          @include rem(padding-left, $gutter-size/2);
          transform: scale(0.8);
          transform-origin: center;
          opacity: 0.5;
          transition: transform 0.5s ease, opacity 0.5s ease;
          outline: none !important;
          cursor: grab;

          &.slick-active {
            transform: scale(1);
            opacity: 1;
          }
        }

          .c-viewer__picture {
            width: auto;
            height: auto;
            margin-right: auto;
            margin-left: auto;
            @include rem(border-radius, 20px);

            @include mq($until: large) {
              max-height: 60vh;
            }

            @include mq($from: large) {
              max-height: 75vh;
            }
          }


    /**
     * FOREGROUND
     *********************** */
    .c-viewer__foreground {
      pointer-events: none;
      z-index: 2;

      @include mq($until: small) {
        @include rem(margin-top, -$gutter-size);
      }

      @include mq($from: small, $until: large) {
        @include rem(margin-top, -$gutter-size*4);
      }

      @include mq($until: large) {
        position: relative;
      }

      @include mq($from: large) {
        position: absolute;
        right: 0;
        bottom: -10%;
        left: 0;
      }
    }

      .c-viewer__card {
        position: relative;
        margin-left: auto;
        color: $color-black;
        background-color: $color-white;
        @include rem(box-shadow, 0 2px 30px rgba($color-secondary, 0.3));
        pointer-events: all;

        @include mq($until: small) {
          @include rem(padding, $gutter-size);
          @include rem(border-radius, 10px);
        }

        @include mq($from: small) {
          @include rem(border-radius, 20px);
        }

        @include mq($from: small, $until: large) {
          @include rem(padding, $gutter-size*1.5);
        }

        @include mq($until: large) {
          @include rem(max-width, 480px);
          margin-right: auto;
        }

        @include mq($from: large) {
          @include rem(max-width, 380px);
          margin-right: 0;
          @include rem(padding, $gutter-size*2.5 $gutter-size*2 $gutter-size*2 $gutter-size*2.5);
        }
      }

        .c-viewer__icon {
          position: absolute;

          @include mq($until: medium) {
            @include rem(width, 40px);
            @include rem(height, 40px);
          }

          @include mq($until: large) {
            @include rem(top, -$gutter-size);
            @include rem(right, $gutter-size);
          }

          @include mq($from: large) {
            @include rem(top, -$gutter-size*1.5);
            @include rem(right, $gutter-size*2);
          }
        }

        .c-viewer__title {
          font-family: $font-secondary;
          font-weight: $font-bold;

          @include mq($until: small) {
            @include font-size(22px, 28px);
          }

          @include mq($from: small, $until: large) {
            @include font-size(28px, 34px);
          }

          @include mq($from: large) {
            @include font-size(36px, 42px);
          }
        }

        .c-viewer__description {
          @include rem(padding-right, 20px);

          @include mq($until: small) {
            @include font-size(13px, 18px);

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/2);
            }
          }

          @include mq($from: small, $until: large) {
            @include font-size(16px, 24px);

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size*0.75);
            }
          }

          @include mq($from: large) {
            @include font-size(18px, 26px);

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size);
            }
          }
        }
