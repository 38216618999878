/**
 * SETTINGS
 * Name: Layout
 ********************************* */


/**
 * BREAKPOINTS
 *********************** */
$mq-breakpoints: (
  "small": 480px,
  "medium": 720px,
  "large": 980px,
  "xlarge": 1420px,
  "wide": 1600px
);


/**
 * CONTAINER
 *********************** */
$container-xsmall: 780px;
$container-small: 900px;
$container-width: 980px;
$container-large: 1280px;
$container-wide: 1580px;


/**
 * GUTTER
 *********************** */
$gutter-size: 20px;


/**
 * Z-INDEX
 *********************** */
$z-background: 1;
$z-foreground: 5;
$z-header: 9;
$z-footer: 19;
$z-overlay: 199;
$z-cursor: 999;


/**
 * REM
 *********************** */
$rem-fallback: true;
$rem-baseline: 10px;
