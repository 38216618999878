$checkbox-font-size: 20px;

.c-checkbox {
  position: relative;

  margin-right: em(10px, $checkbox-font-size);

  font-size: $checkbox-font-size;
  line-height: 1;
  vertical-align: sub;

  cursor: pointer;
}

  .c-checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    margin: 0;

    border: 0;

    background-color: transparent;

    opacity: 0;
    cursor: pointer;
    appearance: none;
  }

  .c-checkbox__box {
    position: relative;

    display: inline-block;
    width: em(16px, $checkbox-font-size);
    height: em(16px, $checkbox-font-size);

    border: em(2px, $checkbox-font-size) solid $color-silver;
    border-radius: em(4px, $checkbox-font-size);

    transition: border-color .5s;

    &::after {
      content: "";

      position: absolute;
      top: 50%;
      left: 50%;

      width: em(16px / 2, $checkbox-font-size);
      height: em(16px / 2, $checkbox-font-size);

      margin-top: em(-16px / 4, $checkbox-font-size);
      margin-left: em(-16px / 4, $checkbox-font-size);

      border-radius: em(2px, $checkbox-font-size);

      background-color: $color-secondary;

      transform: scale(0);
      transform-origin: center;

      transition: transform .5s;

      .c-checkbox__input:checked + & {
        transform: scale(1);
      }
    }

    .c-checkbox__input[disabled] + & {
      background-color: rgba($color-silver, .25);
    }

    .c-checkbox__input:focus + & {
      border-color: $color-foreground;
    }
  }

// States
.c-checkbox--invalid {

  .c-checkbox__box {
    border-color: $color-error;
  }
}