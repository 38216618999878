/**
 * LAYOUT
 * Name: Header
 ********************************* */

 .l-header {
  position: relative;
  pointer-events: none;
  opacity: 1;
  z-index: $z-header;
  transition: opacity 0.5s ease;

  body.is-overlayed & {
    pointer-events: none;
    opacity: 0;
  }

  @include mq($from: small) {

    &::before {
      content: "";
      position: fixed;
      display: block;
      width: 1px;
      top: 0;
      bottom: 0;
      @include rem(left, 46px);
      background: rgba($color-secondary, 0.8);
      opacity: 0.15;
      z-index: 1;
    }
  }

  @include mq($from: medium) {
    position: fixed;
    @include rem(width, 120px);
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);

    &::before {
      position: absolute;
      @include rem(left, 60px);
    }
  }

  @include mq($from: wide) {
    left: calc(50% - 800px);
  }
}


  /**
   * TITLE
   *********************** */
  .l-header__title {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(220px);
    margin: rem(20px);
    font-size: 0;
    line-height: 0;
    text-decoration: none;
    transition: color 0.25s ease;
    pointer-events: auto;
    z-index: 2;

    &:hover {
      color: $color-secondary;
    }

    @include mq($from: medium) {
      @include rem(margin, 34px);
    }
  }

    .l-header__logo {
      display: inline-block;
      @include rem(width, 52px);
      @include rem(height, 52px);
      vertical-align: top;
    }

    .l-header__name {
      display: inline-block;
      @include rem(padding, 18px 20px);
      font-family: $font-secondary;
      @include font-size(14px, 16px);
      vertical-align: top;

      @include mq($until: large) {
        display: none;
      }
    }


  /**
   * CONTACT
   *********************** */
  .l-header__contact {

    @include mq($until: medium) {
      display: none;
    }

    @include mq($from: medium) {
      position: absolute;
      @include rem(height, 30px);
      top: 0;
      bottom: 0;
      @include rem(left, 61px);
      margin: auto 0;
      @include rem(padding, 4px 10px 6px 10px);
      font-family: $font-secondary;
      @include font-size(15px, 20px);
      text-transform: uppercase;
      text-decoration: none;
      white-space: nowrap;
      transform: translate(-50%) rotate(-90deg);
      transition: color 0.25s ease;
      pointer-events: auto;
      overflow: hidden;
      z-index: 3;

      &::before {
        content: "";
        position: absolute;
        display: block;
        height: 1px;
        top: -1px;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto 0;
        background: $color-secondary;
        transform: scaleX(0);
        transform-origin: right;
        opacity: 0;
        transition-property: opacity, transform, height;
        transition-duration: 250ms;
        transition-delay: 500ms, 250ms, 0ms;
        z-index: -1;
      }

      &:hover {
        color: $color-white;
        transition-delay: 500ms;

        &::before {
          height: 100%;
          transform: scaleX(1);
          transform-origin: left;
          opacity: 1;
          transition-delay: 0ms, 250ms, 500ms;
        }
      }
    }
  }

