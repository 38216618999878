/**
 * COMPONENT
 * Name: Logo
 ********************************* */

.c-logo {
  position: relative;
}


  /**
   * LINK
   *********************** */
  .c-logo__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }


    /**
     * ICON
     *********************** */
    .c-logo__icon {
      flex: 0 0 auto;
    }


    /**
     * LABEL
     *********************** */
    .c-logo__label {

      @include mq($until: xlarge) {
        display: none;
      }

      @include mq($from: xlarge) {
        font-family: $font-secondary;
        @include font-size(14px, 16px);

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size);
        }
      }
    }
