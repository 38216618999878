/**
 * LAYOUT
 * Name: Container
 ********************************* */

.l-container {
  @include rem(max-width, $container-width);
  margin-right: auto;
  margin-left: auto;
  @include rem(padding-right, $gutter-size);
  @include rem(padding-left, $gutter-size);

  .l-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.l-container--xsmall {
  @include rem(max-width, $container-xsmall);
}

.l-container--small {
  @include rem(max-width, $container-small);
}

.l-container--large {
  @include rem(max-width, $container-large);
}

.l-container--wide {
  @include rem(max-width, $container-wide);
}
