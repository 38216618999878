/**
 * SETTINGS
 * Name: Colors
 ********************************* */

$color-black: #000;
$color-white: #fff;
$color-grey: #828282;
$color-silver: #bababa;

$color-blue: #341cff;
$color-yellow: #fff2cc;
$color-red: #e64040;


/**
 * MAIN
 *********************** */
$color-primary: $color-blue;
$color-secondary: $color-blue;

$color-foreground: $color-black;
$color-background: $color-yellow;

$color-success: $color-blue;
$color-error: $color-red;


/**
 * UTILS
 *********************** */
$colors: (
  "black": $color-black,
  "white": $color-white,
  "blue": $color-blue,
  "yellow": $color-yellow,
  "red": $color-red
);
