/**
 * COMPONENT
 * Name: Manifest
 ********************************* */

.c-manifest {
  position: relative;

  &:not(:first-child) {

    @include mq($until: medium) {
      @include rem(margin-top, $gutter-size*3);
    }

    @include mq($from: medium) {
      @include rem(margin-top, $gutter-size*6);
    }
  }
}


  /**
   * CONTAINER
   *********************** */
  .c-manifest__container {

    @include mq($from: small, $until: medium) {
      @include rem(max-width, 480px);
      margin-right: auto;
      margin-left: auto;
      @include rem(padding-right, 50px);
      @include rem(padding-left, 50px);
    }

    @include mq($from: medium) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    @include mq($from: medium, $until: large) {
      @include rem(margin-right, -20px);
      @include rem(margin-left, -20px);
    }

    @include mq($from: large) {
      @include rem(margin-right, -50px);
      @include rem(margin-left, -50px);
    }
  }


    /**
     * SIDE
     *********************** */
    .c-manifest__side {

      @include mq($until: medium) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size*2);
        }
      }

      @include mq($from: medium) {
        width: 50%;
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-right, $gutter-size);
        @include rem(padding-left, $gutter-size);
      }

      @include mq($from: large) {
        @include rem(padding-right, 50px);
        @include rem(padding-left, 50px);
      }
    }


      /**
       * ILLUSTRATION
       *********************** */
      .c-manifest__illustration {
        position: relative;
        @include rem(max-width, 380px);
        margin-right: auto;
        color: $color-white;
        // background-color: $color-secondary;
        @include rem(border-radius, 20px);
        overflow: hidden;

        @include mq($until: medium) {
          margin-left: auto;
        }

        @include mq($from: medium) {
          margin-left: 0;
        }
      }

        .c-manifest__picture {
          display: block;
          // opacity: 0.2;
        }

        .c-manifest__icon {
          position: absolute;
          width: 52.5%;
          height: auto;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }


      /**
       * CONTENT
       *********************** */
      .c-manifest__content {

        @include mq($from: medium) {
          @include rem(padding-top, 120px);
        }
      }

        .c-manifest__title {
          font-family: $font-secondary;
          font-weight: $font-bold;
          color: rgba($color-black, 0.8);

          // @include mq($from: medium) {
          //   @include rem(max-width, 300px);
          // }

          @include mq($until: large) {
            @include font-size(30px, 45px);
          }

          @include mq($from: large) {
            @include font-size(40px, 60px);
          }
        }

        .c-manifest__description {

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size);
          }

          @include mq($until: large) {
            @include font-size(16px, 24px);
          }

          @include mq($from: large) {
            @include font-size(18px, 30px);
          }
        }

          .c-manifest__text {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size);
            }
          }
