/**
 * COMPONENT
 * Name: Introduction
 ********************************* */

.c-introduction {
  position: relative;
}


  /**
   * CONTAINER
   *********************** */
  .c-introduction__container {
    position: relative;
  }


    /**
     * ILLUSTRATION
     *********************** */
    .c-introduction__illustration {
      @include rem(border-radius, 311px);
      overflow: hidden;
      z-index: 1;

      @include mq($until: small) {
        @include rem(top, -$gutter-size/2);
      }

      @include mq($from: small, $until: medium) {
        @include rem(top, -$gutter-size*2);
      }

      @include mq($from: medium, $until: large) {
        @include rem(top, -$gutter-size*4);
      }

      @include mq($until: large) {
        position: relative;
        width: 82%;
        @include rem(max-width, 622px);
        height: auto;
        margin-right: auto;
        margin-left: auto;
      }

      @include mq($from: large) {
        position: absolute;
        @include rem(width, 622px);
        @include rem(height, 622px);
        @include rem(top, -160px);
        @include rem(left, 160px);
      }
    }

      .c-introduction__picture {}


    /**
     * CONTENT
     *********************** */
    .c-introduction__content {
      position: relative;
      @include rem(max-width, 380px);
      margin-left: auto;
      z-index: 2;

      @include mq($until: small) {
        @include rem(margin-top, -$gutter-size);
      }

      @include mq($from: small, $until: medium) {
        @include rem(margin-top, -$gutter-size*4);
      }

      @include mq($from: medium, $until: large) {
        @include rem(margin-top, -$gutter-size*8);
      }

      @include mq($until: large) {
        margin-right: auto;
      }

      @include mq($from: large) {
        margin-right: 0;
        @include rem(padding-top, 200px);
      }
    }

      .c-introduction__inner {
        text-align: center;
        background-color: $color-white;

        @include mq($until: medium) {
          @include rem(padding, $gutter-size $gutter-size*1.5 $gutter-size*1.5);
        }

        @include mq($from: medium) {
          @include rem(padding, 30px 45px 50px);
        }
      }

      .c-introduction__label {
        font-family: $font-secondary;
        font-weight: $font-bold;
        @include rem(letter-spacing, $letter-spacing*2);
        color: $color-primary;

        @include mq($until: medium) {
          @include font-size(30px, 45px);
        }

        @include mq($from: medium) {
          @include font-size(40px, 60px);
        }
      }

      .c-introduction__title {
        font-family: $font-secondary;
        font-weight: $font-bold;
        text-transform: uppercase;
        @include rem(letter-spacing, $letter-spacing);

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size);
        }

        @include mq($until: medium) {
          @include font-size(16px, 24px);
        }

        @include mq($from: medium) {
          @include font-size(20px, 30px);
        }
      }

      .c-introduction__description {
        font-weight: $font-bold;

        &:not(:first-child) {
          border-top: 1px solid rgba($color-secondary, 0.4);
        }

        @include mq($until: medium) {
          @include font-size(16px, 26px);

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size);
            @include rem(padding-top, $gutter-size);
          }
        }

        @include mq($from: medium) {
          @include font-size(18px, 30px);

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size*1.5);
            @include rem(padding-top, $gutter-size*1.5);
          }
        }
      }

      .c-introduction__more {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/2);
        }
      }

        .c-introduction__link {
          @include font-size(14px, 24px);
          color: $color-foreground !important;
        }
