/**
 * LAYOUT
 * Name: Wrapper
 ********************************* */

.l-wrapper {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease;
  overflow: hidden;
  z-index: 1;

  body.is-loaded & {
    opacity: 1;
  }

  body.is-overlayed & {
    opacity: 0.1;
    transition: opacity 1.5s ease;
    pointer-events: none;
  }
}


  /**
   * BACKGROUND
   *********************** */
  .l-wrapper__background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }

    .l-wrapper__picture {
      display: block;
      width: 100%;
      height: auto;

      @include mq($until: small) {
        width: 400vw;
      }

      @include mq($from: small, $until: medium) {
        width: 300vw;
      }

      @include mq($from: medium, $until: large) {
        width: 200vw;
      }

      @include mq($until: large) {
        position: absolute;
        max-width: none;
        height: auto;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }


  /**
   * FOREGROUND
   *********************** */
  .l-wrapper__foreground {
    position: relative;
    z-index: 2;
  }
