/**
 * COMPONENT
 * Name: Loader
 ********************************* */


/**
 * KEYFRAMES
 *********************** */
@keyframes loader {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}


/**
 * STYLES
 *********************** */
.c-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-background;
  transition: opacity 0.6s ease, z-index 0.6s ease;
  opacity: 0;
  z-index: -1;

  &.is-active {
    transition: none;
    opacity: 1;
    z-index: 9999;
  }

  html.no-js & {
    opacity: 0;
    z-index: -1;
  }
}

  .c-loader__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -webkit-animation: loader 1.5s linear 0 infinite;
    -moz-animation: loader 1.5s linear 0 infinite;
    -ms-animation: loader 1.5s linear 0 infinite;
    -o-animation: loader 1.5s linear 0 infinite;
    animation: loader 1.5s linear 0 infinite;
    animation-name: loader;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
