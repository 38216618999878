a.c-link:not(.c-link--container),
.c-link a,
.c-link--container .a {
  background-image: linear-gradient(to right, $color-secondary 33.333%, transparent 33.333%, transparent 66.666%, $color-secondary 66.666%);
  background-repeat: no-repeat;
  background-position: 100% bottom;
  background-size: 300% 1px;

  color: $color-secondary;
  font-weight: inherit;
  text-decoration: none;

  transition-property: background-position, color;
  transition-duration: 0ms, 250ms;
  transition-timing-function: ease-out, ease-ine;

  .t-primary:not(body) .t-primary &,
  body .t-primary & {
    color: $color-white;
  }

  .t-light & {
    color: $color-secondary;
  }

  .no-js &:hover,
  &.is-hover,
  &:focus {
    background-position: 0% bottom;

    text-decoration: none;

    transition-duration: 750ms, 250ms;
  }
}

a.c-link.c-link--auto:not(.c-link--container),
.c-link.c-link--auto a,
.c-link--container .a {
  color: inherit;
}

a.c-link--alt:not(.c-link--container),
.c-link--alt a,
.c-link--container .a {
  background-image:
    linear-gradient(to right, $color-secondary 50%, transparent 50%),
    linear-gradient(to left, $color-secondary 50%, transparent 50%);
  background-position: 100% bottom, 0% bottom;
  background-size: 0% 1px, 200% 1px;

  transition-duration: 250ms;

  .t-primary & {
    background-image:
      linear-gradient(to right, currentColor 50%, transparent 50%),
      linear-gradient(to left, currentColor 50%, transparent 50%);
  }

  .no-js &:hover,
  &.is-hover {
    background-position: 0% bottom, 100% bottom;
    background-size: 200% 1px, 0% 1px;

    transition-duration: 250ms;
  }
}

a.c-link.c-link--light:not(.c-link--container),
.c-link.c-link--light a,
.c-link--container .a.c-link--light {
  background-image: linear-gradient(to right, currentColor 33.333%, transparent 33.333%, transparent 66.666%, currentColor 66.666%);
}