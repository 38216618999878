/**
 * LAYOUT
 * Name: Footer
 ********************************* */

.l-footer {
  position: relative;
  @include rem(padding, $gutter-size*2 $gutter-size);
  color: $color-foreground;
  background: $color-white;
  @include font-size(14px, 24px);
  transition: opacity 0.5s ease, filter 0.5s ease;
  z-index: $z-footer;

  body.is-overlayed & {
    opacity: 0.5;
    filter: blur(20px);
    transition: opacity 1.5s ease, filter 1.5s ease;
    pointer-events: none;
  }
}

  .l-footer__link {
    color: #5b5b5b;
  }

    .l-footer__icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      font-size: 2em;
      vertical-align: -0.2em;
    }

  .l-footer__block {
    padding-bottom: rem(20px);
  }

    .l-footer__title,
    .l-footer__text,
    .l-footer__item,
    .l-footer__form {
      margin-bottom: rem(20px);
    }

    .l-footer__title {
      color: $color-foreground;
      font-family: $font-secondary;

      .l-footer__items + & {
        margin-top: rem(30px);
      }
    }

    .l-footer__form {
      position: relative;

      margin-bottom: rem(35px);
    }

      .l-footer__field {
        display: flex;

        &--small {
          @include font-size(12px, 20px);
        }

        & + & {
          margin-top: rem(18px);
        }
      }

      .l-footer__input,
      .l-footer__submit {
        position: relative;
        z-index: 2;

        margin: rem(-15px 0 0 0);
        padding: rem(15px 0 14px 0);

        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        background: #fff none;

        color: #5b5b5b;
        font-family: $font-primary;
        @include font-size(15px, 24px);

        outline: none;

        appearance: none;
      }

      .l-footer__input {
        flex: auto 1 0;

        // Prevent submit button to overflow on an iPhone SE.
        width: calc(100% - #{rem(80px)});

        padding-right: rem(20px);

        transition: border-color 250ms;

        &:focus {
          border-color: $color-foreground;
        }
      }

      .l-footer__submit {
        flex: auto 0 1;

        cursor: pointer;

        animation: show-submit .5s;

        &::after {
          content: "";

          position: absolute;
          right: 0;
          bottom: -1px;
          left: 0;

          display: block;

          border-bottom: 1px solid $color-secondary;

          transform: scaleX(0);
          transform-origin: right;

          transition: transform 250ms;
        }

        &:not(:disabled):focus,
        &:not(:disabled):hover {
          &::after {
            transform: scale(1);
            transform-origin: left;
          }
        }

        &:disabled {
          cursor: wait;

          &::after {
            animation: footer-loading 1.1s infinite linear;
          }
        }

        &.is-hidden {
          display: none;
        }
      }

    .l-footer__response {
      margin-top: 0;
      margin-bottom: rem(35px);
      padding: rem(14px 20px);

      border: 1px solid #ccc;
      border-radius: rem(20px);

      &--success {
        border-color: $color-success;

        background-color: rgba($color-success, .2);
      }

      &--error {
        border-color: $color-error;

        background-color: rgba($color-error, .2);
      }
    }

    .l-footer__items {
      columns: 2;

      &--full {
        columns: 1;
      }
    }

      .l-footer__item {

      }

  @keyframes show-submit {
    from {
      max-width: 0;
      overflow: hidden;
    }

    to {
      max-width: rem(150px);
      overflow: visible;
    }
  }

  @keyframes footer-loading {
    0% {
      transform: scale(1);
      transform-origin: right;
    }

    50% {
      transform: scale(0);
      transform-origin: right;
    }

    51% {
      transform: scale(0);
      transform-origin: left;
    }

    100% {
      transform: scale(1);
      transform-origin: left;
    }
  }

  .l-footer__copyright {
    color: $color-grey;
    font-family: $font-secondary;
    @include font-size(12px, 18px);
  }

@include mq($from: medium) {
  .l-footer {
    position: relative;
    @include rem(padding-right, $gutter-size*6);
    @include rem(padding-bottom, $gutter-size/2);
    @include rem(padding-left, $gutter-size*6);
    overflow: hidden;
  }

    .l-footer__container--alt {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      pointer-events: none;
    }

      .l-footer__copyright {
        position: absolute;
        top: 0;
        right: rem(60px);
        bottom: 0;

        height: rem(20px);

        margin: auto 0;

        transform: translate(50%) rotate(-90deg);

        pointer-events: auto;
      }
}

@include mq($from: large) {
  .l-footer__block {
    width: 50%;

    padding-right: rem(10px);

    float: left;
  }

  .l-footer__block--links {
    max-width: rem(300px);

    padding-right: 0;
    padding-left: rem(10px);

    float: right;
  }
}

@media print {
  .l-footer {
    display: none;
  }
}
