@mixin transition($properties, $duration: $transition-duration, $timing-function: $transition-timing-function) {
  $transition-property: '';

  @each $property in $properties {
    $i: index($properties, $property);

    @if $i == 1 {
      $transition-property: $property;
    }
    @else {
      $transition-property: $transition-property + ', ' + $property;
    }
  }

  transition-property: $transition-property;
  transition-duration: $duration;
  transition-timing-function: $timing-function;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
