.u-align-left {
  text-align: left;
}

.u-align-center {
  text-align: center;
}

.u-align-right {
  text-align: right;
}

.u-unspaced {
  letter-spacing: 0;
}
