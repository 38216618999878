/**
 * COMPONENT
 * Name: Component
 ********************************* */

.c-component {
  position: relative;
}


  /**
   * CONTAINER
   *********************** */
  .c-component__container {}
