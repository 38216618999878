/**
 * SETTINGS
 * Name: Fonts
 ********************************* */


/**
 * IMPORTS
 *********************** */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i&display=swap');


/**
 * FAMILIES
 *********************** */
$font-serif: "Playfair Display", "Georgia", "Times", "Times New Roman", serif;
$font-sans-serif: "Montserrat", "Arial", sans-serif;

$font-primary: $font-sans-serif;
$font-secondary: $font-serif;

$font-default: $font-primary;


/**
 * SIZES
 *********************** */
$font-size: 16px;
$line-height: 24px;
$letter-spacing: 10px;


/**
 * WEIGHTS
 *********************** */
$font-regular: 400;
$font-bold: 700;
