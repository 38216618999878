/**
 * LAYOUT
 * Name: Main
 ********************************* */

.l-main {
  position: relative;
}


  /**
   * HEAD
   *********************** */
  .l-main__head {}


  /**
   * BODY
   *********************** */
  .l-main__body {

    &:not(:first-child) {

      @include mq($until: medium) {
        @include rem(margin-top, $gutter-size*2);
      }

      @include mq($from: medium) {
        @include rem(margin-top, $gutter-size*4);
      }
    }
  }


  /**
   * FOOT
   *********************** */
  .l-main__foot {

    &:not(:first-child) {

      @include mq($until: medium) {
        @include rem(margin-top, $gutter-size*3);
      }

      @include mq($from: medium) {
        @include rem(margin-top, $gutter-size*6);
      }
    }
  }
